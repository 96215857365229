export const routes = [
    // 论坛
    {
        path: '/forum',
        name: 'forum',
        meta:{title:'论坛'},
        component: () => import('@/views/forum/index.vue'),

    },
    {
        name: 'forumSee',
        path: "/forum/forumSee",
        meta:{title:'查看论坛'},
        component: () => import('@/views/forum/ForumSee.vue'),
    },
    {
        name: 'forumEditor',
        path: "/forum/forumEditor",
        meta:{title:'编辑发布',login:true},
        component: () => import('@/views/forum/ForumEditor.vue'),
    },

]